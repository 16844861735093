import { Box, IconButton, Tooltip } from "@mui/material";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import useSwitchViewport from "./styles";
import { useEffect } from "react";
import { useEditorContext } from "../../../hooks/useMouseMove";

const SwitchViewport = (props) => {
  const { breakpoint, onChange } = props;
  const classes = useSwitchViewport();
  const { setActiveBreakPoint } = useEditorContext();

  useEffect(() => {
    console.log(breakpoint);
  }, [breakpoint]);

  return (
    <Box sx={classes.root}>
      <Tooltip title="Desktop View">
        <IconButton
          className={`${!breakpoint || breakpoint === "lg" ? "active" : ""}`}
          onClick={() => {
            setActiveBreakPoint("")
            onChange("");
          }}
        >
          <PersonalVideoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Mobile View">
        <IconButton
          className={`${breakpoint === "xs" ? "active" : ""}`}
          onClick={() => {
            setActiveBreakPoint("xs");
            onChange("xs");
          }}
        >
          <PhoneIphoneIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SwitchViewport;
