import { Transforms, Editor, Element, Node, Path } from "slate";
import deserialize from "../helper/deserialize";
import { decodeAndParseBase64 } from "../utils/helper";

const avoidDefaultInsert = ["table", "grid"];
const NON_TEXT_TAGS = [
  "ol",
  "ul",
  "img",
  "table",
  "video",
  "a",
  "button",
  "GOOGLE-SHEETS-HTML-ORIGIN",
];
const ALLOWED_TEXT_NODES = [
  "paragraph",
  "title",
  "headingOne",
  "headingTwo",
  "headingThree",
];

const parseCopiedHTML = (html) => {
  const parsed = new DOMParser().parseFromString(html, "text/html");

  // if ol, ul are inside li, remove and push ol,ul after that li to maintain format between our slate list and external source list's json
  parsed.querySelectorAll("li > ul, li > ol").forEach((list) => {
    // Find the parent li
    const parentLi = list.parentElement;

    // Move the list after the parent li
    parentLi.after(list);
  });

  // to handle google docs list
  parsed.querySelectorAll("li p, li div").forEach((element) => {
    const parent = element.parentNode;
    // Move all child nodes of <p> or <div> to its parent <li>
    while (element.firstChild) {
      parent.insertBefore(element.firstChild, element);
    }
    // Remove the <p> or <div> element
    parent.removeChild(element);
  });

  // claude.ai, copy list inbetween, some li tags are not wrapped with ul or ol
  parsed.querySelectorAll("li").forEach((li) => {
    // Check if the parent of <li> is not a <ul> or <ol>
    if (
      !li.parentElement ||
      (li.parentElement.tagName !== "UL" && li.parentElement.tagName !== "OL")
    ) {
      // Create a <ul> element
      const ul = document.createElement("ul");
      // Append the <li> to the <ul>
      ul.appendChild(li.cloneNode(true)); // Clone the <li>
      // Replace the original <li> in the DOM with the <ul>
      li.replaceWith(ul);
    }
  });

  return parsed;
};

const loopChildren = (children = [], defaultInsert) => {
  if (!children?.length) {
    return defaultInsert;
  }

  for (let child of children) {
    if (avoidDefaultInsert.includes(child?.type)) {
      defaultInsert = false;
      break;
    }

    defaultInsert = loopChildren(child.children, defaultInsert);
  }

  return defaultInsert;
};

const getCurrentElement = (editor) => {
  try {
    if (editor.selection) {
      return Node.parent(editor, editor?.selection?.anchor?.path);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const getCurrentElementText = (editor) => {
  try {
    if (editor.selection) {
      return Editor.string(editor, editor?.selection?.anchor?.path);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const insertAtNextNode = (editor, formattedFragment) => {
  try {
    const { selection } = editor;
    if (selection) {
      const parentPath = Path.parent(editor?.selection?.anchor?.path);

      const nextPath = Path.next(parentPath);

      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [{ text: "" }] },
        { at: nextPath }
      );

      Transforms.insertFragment(editor, formattedFragment, { at: nextPath });
    }
  } catch (err) {
    console.log(err);
  }
};

// const handleInsert = (editor, defaultInsert, fragment = []) => {
//   if (
//     getCurrentElementText(editor) &&
//     fragment.some((f) => f.type === "table")
//   ) {
//     insertAtNextNode(editor, fragment);
//   } else {
//     defaultInsert();
//   }
// };

const getTableCellChild = (fragment = []) => {
  const table = fragment.find((node) => node.type === "table");
  const row = table?.children?.find((node) => node.type === "table-row");
  const cell = row?.children?.find((node) => node.type === "table-cell");

  return cell?.children || [];
};

const formatFragment = {
  "list-item": (fragment) => {
    let refactorFragment = [];

    fragment.forEach((a) => {
      if (a.type === "orderedList") {
        refactorFragment = [...refactorFragment, ...(a.children || [])];
      } else {
        a.type = "list-item";
        refactorFragment.push(a);
      }
    });

    return refactorFragment;
  },
  "check-list-item": (fragment) => {
    return fragment.map((a) => {
      a.type = "check-list-item";
      return a;
    });
  },
};

const getFocusedNode = (editor, nodeType = "") => {
  try {
    const [node] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === nodeType,
    });
    return node;
  } catch (err) {
    console.log(err);
  }
};

const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const slateHTML = data?.getData("application/x-slate-fragment");
    const html = data?.getData("text/html");
    const currentEl = getCurrentElement(editor);
    const eltype = currentEl?.type;
    const firstNode = editor?.children?.[0];
    const titlePath = firstNode?.type === "topbanner" ? 1 : 0;
    const isTitlePath =
      editor.needLayout && editor?.selection?.anchor?.path[0] === titlePath;

    if (slateHTML && !formatFragment[eltype]) {
      const [tableCellNode] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });

      const decoded = decodeAndParseBase64(slateHTML);

      if (tableCellNode) {
        const tableCellChild = getTableCellChild(decoded);

        if (tableCellChild?.length) {
          Transforms.insertFragment(editor, tableCellChild);
          return;
        }
      }

      const tableNode = getFocusedNode(editor, "table");
      const onlyTextNode = getFocusedNode(editor, "freegrid");

      // paste only text nodes
      if (onlyTextNode) {
        const text = data?.getData("text/plain");
        Transforms.insertText(editor, text);
        return;
      }

      if (tableNode && tableNode[0]) {
        const defaultInsert = loopChildren(decoded, true);

        if (defaultInsert) {
          insertData(data);
          // } else if (editor.isChatEditor) {
          //   // Only convert table to paragraphs if in chat editor mode
          //   const paragraphs = decoded.map(row =>
          //     row.children.map(cell =>
          //       cell.children.map(paragraph =>
          //         paragraph.children.map(textNode => textNode.text).join('')
          //       ).join(' ')
          //     ).join(' ')
          //   ).join('\n'); // Joining with a newline for separate paragraphs

          //   // Insert text as paragraphs
          //   const textNodes = paragraphs.split('\n').map(text => ({
          //     type: 'paragraph',
          //     children: [{ text }]
          //   }));

          //   Transforms.insertNodes(editor, textNodes);
        } else {
          // do not paste table, grid inside table cell
          // only plain text for internal paste
          const text = data?.getData("text/plain");
          Transforms.insertText(editor, text);
        }
      } else {
        const isTextNode = ALLOWED_TEXT_NODES.includes(decoded?.[0]?.type);

        if (isTitlePath && !isTextNode) {
          insertAtNextNode(editor, decoded);
          return;
        }

        const currentText = getCurrentElementText(editor);

        if (currentText?.trim() && !isTextNode) {
          insertAtNextNode(editor, decoded);
          return;
        }

        insertData(data);
      }
    } else if (html) {
      const parsed = parseCopiedHTML(html);

      const rootElement = parsed.body;

      const isNonText = rootElement
        ? rootElement?.querySelector(NON_TEXT_TAGS.toString())
        : false;

      const isGoogleSheet = parsed.body.querySelector(
        "google-sheets-html-origin"
      );

      if (isGoogleSheet) {
        if (editor.isChatEditor) {
          return;
        }
        const table = rootElement.querySelector("table");

        const colGrp = table.querySelector("colgroup");
        if (colGrp) {
          colGrp.remove();
        }

        const fragment = deserialize(table);
        if (isTitlePath) {
          insertAtNextNode(editor, [fragment]);
        } else {
          Transforms.insertFragment(editor, [fragment]);
        }
        return;
      }

      const fragment = deserialize(parsed.body);

      const formattedFragment = formatFragment[eltype]
        ? formatFragment[eltype](fragment)
        : fragment;

      let is_img_table = false;
      formattedFragment.map((f) => {
        if (f.type === "image" || f?.type?.includes("table")) {
          is_img_table = true;
        }
      });

      if (editor.isChatEditor && is_img_table) {
        return;
      }

      if (isTitlePath && isNonText) {
        insertAtNextNode(editor, formattedFragment);
        return;
      }

      const currentText = getCurrentElementText(editor);

      if (currentText?.trim() && isNonText) {
        insertAtNextNode(editor, formattedFragment);
        return;
      }

      Transforms.insertFragment(editor, formattedFragment);

      return;
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withHtml;
