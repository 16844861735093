import React, { useState } from "react";
import { Button, ButtonGroup, Divider, Grid, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Dropdown,
  MarkButton,
  TextSize,
  BlockButton,
  AccordionButton,
} from "../FormatTools";
import { toolbarGroups } from "../toolbarGroups";
import ColorPicker from "../../Elements/Color Picker/ColorPicker";
import {
  activeMark,
  isBlockActive,
  toggleBlock,
  getBlockActive,
  upateBlockActive,
  addMarkData,
} from "../../utils/SlateUtilityFunctions";
import LinkButton from "../../Elements/Link/LinkButton";
import { AllColors } from "../../Elements/Color Picker/ColorButtons";
import { fontFamilyMap } from "../../utils/font";
import { getBorderColor } from "../../utils/helper";
import SelectTypography from "./MiniTextFormat/SelectTypography";
import SelectSuperSubscript from "./MiniTextFormat/SelectSuperSubscript";
import { ColorResetIcon, TextDefaultStyleIcon } from "../../common/iconListV2";
import FontFamilyAutocomplete from "../FormatTools/FontFamilyAutocomplete";
import { useEditorContext } from "../../hooks/useMouseMove";
import LineSpacing from "../../common/StyleBuilder/fieldTypes/lineSpacing";
import { getPageSettings } from "../../utils/pageSettings";

const allTools = toolbarGroups.flat();
const ButtonComp = {
  AccordionButton: AccordionButton,
};

const TextFormat = (props) => {
  const { classes, editor, onClose, closeMainPopup, customProps } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(null);
  const open = Boolean(anchorEl);
  
  const { element: pageSt } = getPageSettings(editor) || {};
  const pageSettingLine = pageSt?.pageProps?.lineHeight
  const { fontFamilies, theme } = useEditorContext();
  const { activeBreakPoint } = useEditorContext();
  const breakpoint = activeBreakPoint === "" ? "lg" : activeBreakPoint
  const fontWeight = allTools.find((f) => f.format === "fontWeight");
  const fontStyle = allTools.filter(
    (f) =>
      f.type === "mark" &&
      f.format !== "strikethrough" &&
      f.format !== "superscript" &&
      f.format !== "subscript"
  );
  const fontAlign = allTools.filter((f) => f.format?.indexOf("align") >= 0);
  const link = allTools.find((f) => f.format?.indexOf("link") >= 0);
  const lists = allTools.filter((f) => f.group?.indexOf("list") >= 0);
  const { isActive: isBlockQuoteActive, props: bqProps } = getBlockActive(
    editor,
    "blockquote"
  );
  const { color: bqColor, bgColor: bqBgColor } = bqProps || {
    color: "",
    bgColor: "",
  };

  let lineSpacingValue = activeMark(editor, 'lineHeight');
  lineSpacingValue = lineSpacingValue?.[breakpoint] !== undefined ? lineSpacingValue : pageSettingLine
  const handleColorPicker = (type) => (e) => {
    setType(type);
    setAnchorEl(e.currentTarget);
  };

  const updateBlockQuote = (attr) => {
    const upAttr = {
      ...(bqProps || {}),
      ...attr,
    };
    delete upAttr.children;
    delete upAttr.type;
    if (isBlockQuoteActive && (upAttr.color || upAttr.bgColor)) {
      upateBlockActive(editor, "blockquote", upAttr);
    } else {
      toggleBlock(editor, "blockquote", editor.selection, upAttr);
    }
  };

  const onSelectColor = (color) => () => {
    const attr = {
      [type]: color,
    };
    updateBlockQuote(attr);
    setAnchorEl(null);
  };

  const handleQuote = (attr) => () => {
    updateBlockQuote(attr);
  };

  const onClosePicker = () => {
    setAnchorEl(null);
  };

  const handleDefault =
    ({ format, val }) =>
    () => {
      const value = val ? val : "inherit";
      addMarkData(editor, { format, value });
    };

  const handleLineSpacing = (data) => {
    const [[format, value]] = Object.entries(data);
    addMarkData(editor, { format, value });
  }
  return (
    <Grid
      container
      sx={classes.textFormatWrapper}
      className="text-formatter-popup"
    >
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Font Family
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={classes.defaultBtn1}
              startIcon={<TextDefaultStyleIcon />}
              onClick={handleDefault({
                format: "fontFamily",
                val: Object.values(fontFamilyMap)[0],
              })}
              className="npr-0"
            >
              Default Text
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.textFormatField} className="textFormatMUIIcon">
          <FontFamilyAutocomplete
            classes={classes}
            {...fontFamilies}
            editor={editor}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="fw-dd">
        <Grid item xs={6}>
          <Typography variant="body1" color={"primary"} sx={classes.typoLabel3}>
            Font Weight
          </Typography>
          <Grid item xs={12} sx={classes.textFormatField3} className="textFormatMUIIcon">
            <Dropdown
              classes={classes}
              {...fontWeight}
              editor={editor}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} className="npr-0">
          <Typography variant="body1" color={"primary"} sx={classes.typoLabel3}>
            Font Size
          </Typography>
          <Grid item xs={12} sx={classes.textFormatCG}>
            <TextSize
              classes={classes}
              format={"fontSize"}
              activeMark={activeMark}
              editor={editor}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={classes.textFormatField2}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Text Color
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={classes.defaultBtn1}
              startIcon={<ColorResetIcon />}
              onClick={handleDefault({ format: "color" })}
            >
              Default Color
            </Button>
          </Grid>
        </Grid>
        <Grid sx={classes.textFormatColorWrpr}>
          <ColorPicker
            format={"color"}
            activeMark={activeMark}
            editor={editor}
            showHex={false}
            rounded={true}
            title="Text Color"
            key={"11_cc"}
            id={"11_cc"}
            classes={classes}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.textFormatField2}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Background Color
            </Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Button
                sx={classes.defaultBtn2}
                startIcon={<ColorResetIcon stroke={ theme?.palette?.type === "dark" ? "#505359" : "#A2B0B9"} />}
                onClick={handleDefault({ format: "bgColor" })}
              >
                Default Color
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.textFormatColorWrpr}>
          <ColorPicker
            format={"bgColor"}
            activeMark={activeMark}
            editor={editor}
            showHex={false}
            rounded={true}
            title="Text Background Color"
            key={"12_cc"}
            classes={classes}
            id={"12_cc"}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid item xs={6} sx={classes.textFormatField}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel2}>
          Alignment
        </Typography>
        <Grid item xs={12} sx={classes.textFormatCG}>
          {fontAlign?.map((m, i) => {
            return (
              <BlockButton
                key={`pptool_block_${i}_${m.id}`}
                editor={editor}
                {...m}
              />
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={6} sx={classes.textFormatField}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel2}>
          Lists
        </Typography>
        <Grid item xs={12} sx={classes.textFormatCG}>
          {lists?.map((m, i) => {
            const Comp = ButtonComp[m?.component] || BlockButton;
            return (
              <Comp key={`pptool_block_${i}_${m.id}`} editor={editor} {...m} />
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Typography
        </Typography>
        <Grid item xs={12} className="typo-icons" sx={classes.evenSpace}>
          {/* {typo?.map((m, i) => {
            return (
              <BlockButton
                key={`pptool_mark_${i}_${m.id}`}
                editor={editor}
                {...m}
              />
            );
          })} */}
          <SelectTypography
            classes={classes}
            editor={editor}
            closeMainPopup={closeMainPopup || onClose}
          />
          {fontStyle?.map((m, i) => {
            return (
              <MarkButton
                key={`pptool_mark_${i}_${m.id}`}
                editor={editor}
                {...m}
              />
            );
          })}
          <LinkButton
            key={link.id}
            active={isBlockActive(editor, link.format)}
            editor={editor}
            customProps={customProps}
          />
          <SelectSuperSubscript
            classes={classes}
            editor={editor}
            closeMainPopup={closeMainPopup || onClose}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Line Spacing
        </Typography>
        <Grid item xs={12} className="typo-icons" sx={classes.evenSpace}>
          <LineSpacing value={ lineSpacingValue } onChange = {handleLineSpacing}  data={{ key:'lineHeight'}} />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sx={classes.evenSpace}
        style={{ justifyContent: "space-between" }}
      >
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel3}>
          Decorations
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={classes.evenSpace}
        style={{ justifyContent: "space-between" }}
        className="text-decorations-wrpr"
      >
  <ButtonGroup sx={classes.btnGroup}>
          <Button
            className={`no-hover ${bqBgColor ? "active" : ""} colorBox`}
            onClick={handleQuote({
              bgColor: !bqBgColor ? "rgb(227, 236, 255)" : null,
            })}
            style={{ background: bqBgColor }}
          >
            Colorbox
          </Button>
          <div className="vl"></div>
          <Button onClick={handleColorPicker("bgColor")} className="colorPicker textFormatMUIIcon">
            <KeyboardArrowDownRoundedIcon />
          </Button>
        </ButtonGroup>
  <ButtonGroup sx={classes.btnGroup2}>
          <Button
            className={`no-hover colorBox`}
            onClick={handleQuote({
              color: !bqColor ? "rgb(47, 94, 188)" : null,
            })}
          >
            <blockquote
              style={{
                paddingLeft: "12px",
                ...getBorderColor(bqColor || "transparent", 3),
                borderWidth: bqColor ? "0px 0px 0px 3px" : "0px",
              }}
            >
              Quote
            </blockquote>
          </Button>
          <div className="vl"></div>
          <Button onClick={handleColorPicker("color")} className="colorPicker textFormatMUIIcon">
            <KeyboardArrowDownRoundedIcon />
          </Button>
        </ButtonGroup>
        {/* <Button
          onClick={handlePageWidth("fixed")}
          startIcon={<WidthNormalIcon />}
          sx={classes.pageWidthBtn}
          className={pageWidth === "fixed" || !pageWidth ? "active" : ""}
          style={{ width: "45%" }}
        >
          Centered
        </Button>
        <Button
          sx={classes.pageWidthBtn}
          className={pageWidth === "full" ? "active" : ""}
          onClick={handlePageWidth("full")}
          startIcon={<WidthFullIcon />}
          style={{ width: "45%" }}
        >
          Full width
        </Button> */}
      </Grid>
      <AllColors
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePicker}
        classes={classes}
        onSelect={onSelectColor}
        id="all_1"
      />
    </Grid>
  );
};

export default TextFormat;
