import { useMemo } from "react";
import { activeMark, addMarkData, isBlockActive, toggleBlock } from "../../../utils/SlateUtilityFunctions";
import CustomSelectTool from "./CustomSelectTool"
import useWindowResize from "../../../hooks/useWindowResize";
import { BREAKPOINTS_DEVICES, getBreakPointsValue } from "../../../helper/theme";
import { sizeMap } from "../../../utils/font";

function Label({ label, type }) {
    return <span><strong>{label}</strong> {type}</span>
}

function DisplayHeaderLabel({ type }) {
    return <div>H<sub>{type}</sub></div>;
}

const typographyOptions = [
    {
        id: 11,
        format: "headingOne",
        type: "block",
        title: <Label label="H1" type="Header" />,
        label: <DisplayHeaderLabel type={1} />,
        group: "typography",
        value: "headingOne"
    },
    {
        id: 12,
        format: "headingTwo",
        type: "block",
        title: <Label label="H2" type="Header" />,
        label: <DisplayHeaderLabel type={2} />,
        group: "typography",
        value: "headingTwo"
    },
    {
        id: 13,
        format: "headingThree",
        type: "block",
        title: <Label label="H3" type="Header" />,
        label: <DisplayHeaderLabel type={3} />,
        group: "typography",
        value: "headingThree"
    },
    {
        id: 14,
        format: "fontSize",
        type: "mark",
        title: <Label label="L" type="Large" />,
        label: "L",
        group: "typography",
        value: "huge"
    },
    {
        id: 15,
        format: "fontSize",
        type: "mark",
        title: <Label label="M" type="Medium" />,
        label: "M",
        group: "typography",
        value: "medium"
    },
    {
        id: 16,
        format: "fontSize",
        type: "mark",
        title: <Label label="S" type="Small" />,
        label: "S",
        group: "typography",
        value: "small"
    },
]

function SelectTypography({ editor, classes, closeMainPopup }) {
    const [size] = useWindowResize();

    const updateMarkData = (newVal) => {
        const val = activeMark(editor, "fontSize");

        let upData = {
            ...getBreakPointsValue(val),
            [size?.device]: `${newVal}px`,
        };

        // if desktop update to all other devices
        // to avoid default normal size
        if (size?.device === "lg") {
            upData = BREAKPOINTS_DEVICES.reduce((a, b) => {
                a[b] = `${newVal}px`;
                return a;
            }, {});
        }

        addMarkData(editor, {
            format: "fontSize",
            value: {
                ...upData,
            },
        });
    };

    const selectedBlock = useMemo(() => {
        return typographyOptions.find(t => {
            if (t.type === "block") {
                return isBlockActive(editor, t.format);
            } else if (t.type === "mark") {
                const val = activeMark(editor, t.format);
                if (val) {
                    const value = getBreakPointsValue(val, size?.device);

                    let selected;

                    Object.entries(sizeMap).forEach(([key, v]) => {
                        if (v === value) {
                            selected = key;
                        }
                    })

                    return selected === t.value;
                }
            }

            return false;
        })
    }, [typographyOptions, activeMark, isBlockActive, editor]);

    const onChange = (format, option) => {
        // add/reset block elements
        toggleBlock(editor, format);

        if (option.type === "block") {
            // reset old font size
            let updatedValue = !selectedBlock ? {} : { xs: "16px", sm: "16px", md: "16px", lg: "16px" }
            addMarkData(editor, {
                format: "fontSize",
                value: updatedValue
            });
        } else if (option.type === "mark") {
            const size = sizeMap[option.value] || "";
            const [sizeInNumber] = size.split("px");
            updateMarkData(Number(sizeInNumber));
        }

        closeMainPopup();
    };

    return (
        <CustomSelectTool
            options={typographyOptions}
            editor={editor}
            onChange={onChange}
            value={selectedBlock?.value || "headingOne"}
            classes={classes}
        />
    )
}

export default SelectTypography