import { useEffect, useRef, useState } from "react";
import { activeMark, addMarkData, isBlockActive } from "../../../utils/SlateUtilityFunctions";
import { Button, IconButton, Popover, TextField } from "@mui/material";
import DownArrowIcon from "../../../assets/svg/DownArrowIcon";
import useWindowResize from "../../../hooks/useWindowResize";
import { BREAKPOINTS_DEVICES, getBreakPointsValue } from "../../../helper/theme";
import { headingMap, sizeMap } from "../../../utils/font";

const fontSizeOptions = [
    8,
    9,
    10,
    11,
    12,
    14,
    16,
    18,
    20,
    22,
    26,
    32,
    36,
    40,
    48,
    64,
    96,
    128
];

function SelectFontSize({ editor, classes }) {
    const format = "fontSize";

    const [fontSize, setFontSize] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const containerRef = useRef();

    const [size] = useWindowResize();
    const val = activeMark(editor, format);
    const value = getBreakPointsValue(val, size?.device);
    const timerRef = useRef();

    const updateMarkData = (newVal) => {
        let upData = {
            ...getBreakPointsValue(val),
            [size?.device]: `${newVal}px`,
        };

        // if desktop update to all other devices
        // to avoid default normal size
        if (size?.device === "lg") {
            upData = BREAKPOINTS_DEVICES.reduce((a, b) => {
                a[b] = `${newVal}px`;
                return a;
            }, {});
        }

        addMarkData(editor, {
            format,
            value: {
                ...upData,
            },
        });
    };

    const onChangeSize = (value) => {
        if (value) {
            let inc = parseInt(value);
            inc = inc > 200 ? 200 : inc;
            updateMarkData(inc);
        } else {
            setFontSize(null)
        }
    };

    const getSizeVal = () => {
        try {
            let size =
                `${value}`?.indexOf("px") >= 0 ? value : sizeMap[value] || value;

            Object.entries(headingMap).forEach(([format, value]) => {
                if (isBlockActive(editor, format) && isNaN(parseInt(size))) {
                    size = value;
                }
            });

            return isNaN(parseInt(size)) ? 14 : parseInt(size);
        } catch (err) {
            return "";
        }
    };

    useEffect(() => {
        setFontSize(getSizeVal())
    }, [value])

    const onChange = (e) => {
        clearTimeout(timerRef.current);

        const value = e.target.value;
        setFontSize(value);

        timerRef.current = setTimeout(() => {
            onChangeSize(value);
        }, 500);
    }

    return (
        <div ref={containerRef} style={{ display: "flex", alignItems: "center" }}>
            <TextField
                sx={classes?.miniFontSizeInput}
                value={fontSize}
                onChange={onChange}
                size="small"
            />

            <IconButton
                onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(containerRef.current);
                }}
            >
                <DownArrowIcon />
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={classes.customSelectPopoverWrapper}
            >
                {fontSizeOptions.map((s, i) => {
                    return (
                        <div key={i}>
                            <Button
                                className={`customSelectOptionLabel ${fontSize === s ? "selected" : ""}`}
                                onClick={() => {
                                    setFontSize(s)
                                    onChangeSize(s);
                                }}
                            >
                                {s}
                            </Button>
                        </div>
                    )
                })}
            </Popover>
        </div>
    );
}

export default SelectFontSize;