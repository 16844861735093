import React, { useRef, useState } from "react";
import { useTheme } from "@mui/material";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Box, IconButton, Popper, Tooltip } from "@mui/material";
import SectionPopup from "../../Elements/Grid/SectionPopup";
import {
  getBreakPointsValue,
  getTRBLBreakPoints,
  groupByBreakpoint,
} from "../../helper/theme";
import DragHandle from "../DnD/DragHandleButton";
import { useEditorSelection } from "../../hooks/useMouseMove";
import SectionStyle from "./styles";
import useWindowResize from "../../hooks/useWindowResize";
import { SectionSettingIcon } from "../iconListV2";

const Toolbar = ({ fromPopper, readOnly, showTool, onSettings }) => {
  return !readOnly && !showTool ? (
    <Box
      component={"div"}
      className={`element-toolbar no-border-button hr section-tw sectionIcon`}
      contentEditable={false}
      style={
        fromPopper
          ? {
              position: "unset",
              marginLeft: "28px",
              paddingTop: "4px",
              marginRight: "10px",
              height: "100%",
            }
          : { left: "-28px", top: "1px" }
      }
    >
      <Tooltip title="Section Settings">
        <IconButton onClick={onSettings}>
          <SectionSettingIcon />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
};

const list_types = ["orderedList", "unorderedList"];

const Section = (props) => {
  const themeReact = useTheme();
  const theme = props?.theme;
  const classes = SectionStyle(themeReact, theme);
  const { children, element, customProps } = props;
  const { readOnly } = customProps;
  const editor = useSlateStatic();
  const [showTool] = useEditorSelection(editor);
  const [openSetttings, setOpenSettings] = useState(false);
  const {
    sectionBgColor,
    sectionBackgroundImage,
    sectionBannerSpacing,
    sectionBorderRadius,
    sectionGridSize,
    sectionAlignment,
  } = element;
  const { vertical, horizantal, flexDirection } = sectionAlignment || {};
  const path = ReactEditor.findPath(editor, element);

  const anchorEl = useRef(null);
  const popperEl = useRef(null);

  const [size] = useWindowResize();
  const isSectionFullWidth =
    sectionGridSize && sectionGridSize[size?.device] >= 12;

  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  const isFreeGrid = element?.children?.find((f) => f.type === "freegrid");
  const needHover = element?.children?.find(
    (f) => f.type === "grid" && !list_types.includes(element.type)
  )
    ? ""
    : "";

  let tempProps = {};
  if (element?.type === "temp") {
    tempProps = {
      "--left": `${element?.left}px`,
      "--top": `${element?.top}px`,
    };
  }

  const sectionBgImage =
    sectionBackgroundImage && sectionBackgroundImage !== "none"
      ? {
          backgroundImage: `url(${sectionBackgroundImage})`,
        }
      : {};

  const edSectionSp = groupByBreakpoint(
    {
      padding: {
        ...getTRBLBreakPoints(sectionBannerSpacing),
      },
      borderRadius: {
        ...getBreakPointsValue(
          sectionBorderRadius || {},
          null,
          "overrideBorderRadius",
          true
        ),
      },
    },
    themeReact
  );

  const edInnerSp = groupByBreakpoint(
    {
      width: {
        ...getBreakPointsValue(
          sectionGridSize || 8,
          null,
          "overrideGridSize",
          true
        ),
      },
    },
    themeReact
  );

  return path.length === 1 && !isFreeGrid ? (
    <Box
      component={"div"}
      className={`ed-section-wrapper ${
        readOnly ? "" : "hselect"
      } ${needHover} is-${element?.type}`}
      style={{
        ...tempProps,
      }}
      sx={{
        ...classes.root,
        background: sectionBgColor,
        ...sectionBgImage,
        ...edSectionSp,
        flexDirection: flexDirection || "column",
        alignItems: horizantal,
        justifyContent: vertical,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        className="ed-section-inner"
        sx={{
          position: "relative",
          ...edInnerSp,
        }}
        ref={anchorEl}
      >
        {isHovering && isSectionFullWidth ? (
          <Popper
            open={isHovering && isSectionFullWidth}
            anchorEl={anchorEl?.current}
            placement="top-start"
            sx={{
              zIndex: 9999,
            }}
            disablePortal={true}
            ref={popperEl}
            className="sectionPopper"
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "30px",
                position: "relative",
                background:
                  theme?.palette?.type === "dark"
                    ? theme?.palette?.editor?.miniToolBarBackground
                    : "#F6F6F6",
              }}
            >
              {!readOnly && !showTool ? (
                <DragHandle {...props} fromPopper={true} />
              ) : null}
              <Toolbar
                fromPopper={true}
                readOnly={readOnly}
                showTool={showTool}
                onSettings={onSettings}
              />
            </Box>
          </Popper>
        ) : (
          <>
            {!readOnly && !showTool ? <DragHandle {...props} /> : null}
            <Toolbar
              readOnly={readOnly}
              showTool={showTool}
              onSettings={onSettings}
            />
          </>
        )}

        {children}
      </Box>

      {openSetttings ? (
        <SectionPopup
          element={{ ...element, sectionGridSize: sectionGridSize || 8 }}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
    </Box>
  ) : (
    children
  );
};

export default Section;
