
export const defaultFonts = [
    // "EB Garamond",
    "Anton",
    "DM Serif Text",
    "Libre Baskerville",
    "Montserrat",
    "Open Sans",
    "Public Sans",
    "Raleway",
    "Space Mono",
    "Great Vibes",
    "Zeyada",
    "Allura",
    "Pinyon Script",
    "Dancing Script",
    "Gaegu",
    "Kite One",
    "Merriweather"
];

export const otherFonts = [
    "PoppinsRegular",
    "PoppinsBold",
    "Monaco",
    "Qwitcher Grypen",
    "Bulgarian Garamond",
    "Redacted Script",
    "Herr Von Muellerhoff",
    "Dawning of a New Day",
    "Coming Soon",
    "Engagement",
    "Ingrid Darling",
    "La Belle Aurore",
    "Mea Culpa",
    "The Girl Next Door",
    "Helvetica",
    "Georgia",
    "Times New Roman",
    "Courier New",
    "Impact",
]

export const googleFontList = [
    "Roboto",
    "Noto Sans JP",
    "Poppins",
    "Lato",
    "Inter",
    "Roboto Condensed",
    "Roboto Mono",
    "Oswald",
    "Noto Sans",
    "Nunito",
    "Nunito Sans",
    "Ubuntu",
    "Rubik",
    "Playfair Display",
    "Noto Sans KR",
    "Roboto Slab",
    "PT Sans",
    "Kanit",
    "Work Sans",
    "Lora",
    "DM Sans",
    "Mulish",
    "Quicksand",
    "Fira Sans",
    "Noto Sans TC",
    "Inconsolata",
    "Barlow",
    "Manrope",
    "IBM Plex Sans",
    "PT Serif",
    "Karla",
    "Titillium Web",
    "Heebo",
    "Noto Serif",
    "Nanum Gothic",
    "Noto Color Emoji",
    "Agdasima",
    "Bebas Neue",
    "Libre Franklin",
    "Mukta",
    "Outfit",
    "Josefin Sans",
    "Source Code Pro",
    "Jost",
    "Space Grotesk",
    "Hind Siliguri",
    "Arimo",
    "Cabin",
    "Barlow Condensed",
    "Dosis",
    "Fira Sans Condensed",
    "Bitter",
    "Archivo",
    "Figtree",
    "Noto Serif JP",
    "PT Sans Narrow",
    "Abel",
    "Noto Sans SC",
    // "Source Sans 3",
    "Hind",
    // "Exo 2",
    "Teko",
    "Oxygen",
    "Cairo",
    "Crimson Text",
    "Plus Jakarta Sans",
    "Overpass",
    "Pacifico",
    "Prompt",
    "Red Hat Display",
    "Varela Round",
    "Cormorant Garamond",
    "Assistant",
    "Comfortaa",
    "Lexend",
    "Signika Negative",
    // "M PLUS Rounded 1c",
    "Fjalla One",
    "Caveat",
    "IBM Plex Mono",
    "Arvo",
    "Lobster",
    "Schibsted Grotesk",
    "Chakra Petch",
    "Maven Pro",
    "Sora", 
    "Kalam", 
    "Onest", 
    "Space Grotesk", 
    "Outfit", 
    'Titillium Web',
    ...defaultFonts
]



