import React, { useEffect } from "react";
import { useDraggable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SectionDragIcon } from "../iconListV2";

const Draggable = (props) => {
  const { id, classes, onDrag } = props;
  const {
    attributes: dragAttributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
  } = useDraggable({
    id: id,
  });

  useEffect(() => {
    if (onDrag) {
      onDrag(isDragging);
    }
  }, [isDragging]);

  const dragStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Box
      ref={setNodeRef}
      {...listeners}
      {...dragAttributes}
      component={"button"}
      className={`dh-para ${isDragging ? "active" : ""}`}
      contentEditable={false}
      style={dragStyle}
      sx={classes.dragHandle}
    >
      <SectionDragIcon />
    </Box>
  );
};

export default Draggable;
